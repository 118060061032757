import React, { useState, useEffect } from "react";
import loadable from "@loadable/component";
import { Modal } from "@mantine/core";
import { useViewportSize } from "@mantine/hooks";
import Carousel from "nuka-carousel";
import Layout from "../../components/Layout/layout";
import SEO from "../../components/seo";
import Section from "../../components-v2/Base/Section";
import Heading from "../../components-v2/Base/HeadingBuilder";
import Image from "../../components/ImageQuerys/RandomImages";
import Offices from "../../components/Offices/Offices";
import {
  getAllDepartments,
  getSalesDepartments,
  getCorporateDepartments,
} from "../../api/greenhouse-api";
import {
  videoQuotes,
  largeFeatureOne,
  largeFeatureTwo,
} from "../../data/careers/sales";
import VideoCard from "../../components-v2/VideoCard";
import useWindowSize from "../../hooks/use-window-size";
import CareersDisplayV2 from "../../components/Careers/CareersDisplay-v2";
import CtaPrimary from "../../components-v2/Base/CtaPrimary";
import chevron from "../../images/svg/chevron_left.svg";
import ogImage from "../../images/global_assets/og-image.png";

const YoutubeVideo = loadable(() => import("../../components-v2/YoutubeVideo"));
const LargeFeatures = loadable(() =>
  import("../../components/LargeFeatures/LargeFeatures")
);

const SalesPage = () => {
  const { width } = useViewportSize();

  const [departments, setDepartments] = useState({
    sales: [],
    product: [],
    corp: [],
  });

  const size = useWindowSize();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [videoQuoteId, setVideoQuoteId] = useState("");
  const [numberOfSlides, setNumberOfSlides] = useState(2);
  const [mostRecent, setMostRecent] = useState([]);

  const onVideoCardClick = (videoId) => {
    setVideoQuoteId(videoId);
    setIsModalOpen(true);
  };

  useEffect(() => {
    const { width } = size;
    const isMobile = width <= 576;
    const isSmallTablet = width > 576 && width < 722;
    const isTablet = width >= 722 && width < 992;
    const isSreenLG = width > 992 && width < 1200;
    const screenXL = width > 1200 && width <= 1700;

    const slides = () => {
      if (isMobile) return 1.1;
      if (isSmallTablet) return 1.9;
      if (isTablet) return 2.5;
      if (isSreenLG) return 3;
      if (screenXL) return 4;
      return 4;
    };

    setNumberOfSlides(slides);
  }, [size]);

  useEffect(() => {
    getAllDepartments()
      .then((areas) => {
        const prefilter = areas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          product: prefilter,
        }));
        return getSalesDepartments();
      })
      .then((salesareas) => {
        const prefilter = salesareas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          sales: prefilter,
        }));
        return getCorporateDepartments();
      })
      .then((corpareas) => {
        const prefilter = corpareas.filter((d) => {
          return d.jobs.length > 0;
        });
        setDepartments((prevState) => ({
          ...prevState,
          corp: prefilter,
        }));
      });
  }, []);

  useEffect(() => {
    const recent = departments.sales[0]?.jobs.slice(0, 4);
    setMostRecent(recent || []);
  }, [departments]);

  return (
    <Layout className="careers-sales">
      <SEO
        title="Careers & Job Culture at SpotOn Sales | SpotOn "
        description="Find a work family not a normal job. We help our employees with growth and team building, for one common goal. Read reviews & join the #SpotOnFamily now."
        image={`https://spoton.com/${ogImage}`}
        cannonical="https://www.spoton.com/careers/sales"
      />

      <a
        className={`
          bg-primary text-white hover:text-white
          fixed top-[30%] -right-16
          rounded-[16px]
          text-[18px] py-4 px-8
          transform -rotate-90
          cursor-pointer group
          z-[11]
        `}
        href="#openings"
      >
        Apply Here
        <img
          src={chevron}
          alt="chevron"
          className="group-hover:translate-x-2 transition transition-all ml-2"
        />
      </a>

      <Section className="mt-32">
        <Heading
          level={1}
          injectionType={2}
          withBlueSymbol
          symbol="!"
          className="max-w-[645px]"
        >
          If you're passionate about helping local businesses grow and thrive -
          We're looking for you! Join Us! From Anywhere
        </Heading>

        <div
          className={`
          flex flex-col lg:flex-row justify-between gap-y-20
          mt-10 md:mt-20 lg:mt-28
        `}
        >
          <div className="flex gap-10">
            <div className="w-[116px]">
              <Image imageName="gptw-usa.png" />
            </div>

            <div className="mr-4 lg:mr-16 xl:mr-32">
              <div className="text-black text-h1 font-bold">79%</div>
              <p className="text-black-200 text-p leading-[160%] max-w-[285px]">
                of employees at{" "}
                <a
                  href="https://www.greatplacetowork.com/certified-company/7045280"
                  className="text-primary hover:text-primary"
                  target="_blank"
                  rel="noreferrer"
                >
                  SpotOn
                </a>{" "}
                say it is a great place to work compared to{" "}
                <span className="font-bold">57%</span> of employees at a typical
                U.S.-based company.
              </p>
            </div>

            <div className="opacity-0 lg:opacity-100 w-0.5 border-1 bg-black-700 rounded-full" />
          </div>

          <p className="text-black text-[20px] lg:pl-10 xl:pl-20 max-w-[600px]">
            At SpotOn, we put our associates above everything else. We’re known
            for our payment software solutions, but we stand out because of the
            hard-working people behind the tech. We are looking for passionate
            self-starters to join our industry-leading team to help restaurants
            and retail businesses compete and win.{" "}
          </p>
        </div>
      </Section>

      <Section className="mt-16 md:mt-32 flex flex-col lg:flex-row justify-between gap-16 items-center">
        <div className="p-8 md:py-[120px] md:px-[58px] bg-primary-50 rounded-[16px]">
          <p className="text-black text-[18px] xl:text-[24px] ">
            “My favorite part of the job is knowing that we get to give
            businesses real tools that actually help them grow and reach their
            dreams. I really love how our company truly does care. Its real,
            true, meaningful value that bleeds through every channel. Being
            authentic, personal, and meaning what I say to each and every client
            is how I find value in my job every day.”
          </p>

          <div className="text-primary text-[18px] xl:text-[24px] mt-8">
            Anna Dawson
          </div>
          <div className="text-black-200 text-[14px]">
            Sales Team Lead, Chicago
          </div>
        </div>

        <div className="w-[inherit] md:w-auto lg:w-full">
          <Image imageName="anna-dawson.png" className="w-full md:w-[384px]" />
        </div>
      </Section>

      <Section
        className={`
        mt-16 md:mt-32 bg-black xl:rounded-[24px] 
      `}
      >
        <div
          className={`
          flex flex-col md:flex-row justify-between items-stretch gap-y-10 gap-x-4
          py-[70px] px-[60px]`}
        >
          <div className="md:basis-[45%]">
            <Heading
              level={3}
              injectionType={3}
              withBlueSymbol
              className="text-white"
            >
              Recent postings
            </Heading>

            <div className="flex flex-col gap-y-2">
              {mostRecent.map((item) => (
                <div>
                  <a
                    href={item.absolute_url}
                    target="_blank"
                    className="text-black-600 hover:text-primary-70 hover:underline text-p"
                    rel="noreferrer"
                  >
                    {item.title}
                  </a>
                </div>
              ))}
            </div>

            <CtaPrimary
              title="Search all jobs"
              target="#openings"
              className="mt-6"
            />
          </div>

          <div
            className="w-[1px] min-h-[100%] bg-black-200 invisible md:visible md:basis-[5%]"
            style={{ minHeight: "100%" }}
          >
            {" "}
          </div>

          <div className=" md:max-w-[270px] lg:max-w-[360px] md:basis-[45%]">
            <Heading
              level={3}
              injectionType={3}
              withBlueSymbol
              className="text-white"
            >
              Stay connected
            </Heading>

            <p className="text-black-600 text-p">
              Join the SpotOn Talent Network to stay updated on latest news and
              job openings near you.
            </p>

            <CtaPrimary
              title="Join our talanted network"
              target="/careers/apply"
              className="mt-6"
              newTab
            />
          </div>
        </div>
      </Section>

      <Section className="mt-16 md:mt-32">
        <Heading
          className="text-center"
          level={2}
          injectionType={2}
          withBlueSymbol
          symbol="?"
        >
          Why Heart & Hustle
        </Heading>
        <p className="text-black-300 text-[20px] max-w-[915px] text-center mx-auto mb-10">
          Hear from RJ Horsley, Chief Operating Officer, on why working at
          SpotOn could be the most challenging yet rewarding experience of your
          career.
        </p>
        <YoutubeVideo videoUrl="W7tmBBMlfqw" videoPoster="sales.png" />
      </Section>

      <Section className="mt-16 md:mt-32">
        <Heading
          className="text-center"
          level={2}
          injectionType={2}
          withBlueSymbol
        >
          Why you’ll love working at SpotOn
        </Heading>
        <p className="text-black-300 text-[20px] max-w-[915px] text-center mx-auto mb-10">
          Hear from our sales team
        </p>

        <Carousel
          slidesToShow={numberOfSlides}
          scrollMode="page"
          renderCenterLeftControls={() => null}
          renderCenterRightControls={() => null}
        >
          {videoQuotes.map((video) => (
            <VideoCard
              {...video}
              onClick={() => onVideoCardClick(video.videoId)}
            />
          ))}
        </Carousel>

        <Modal
          opened={isModalOpen}
          onClose={() => setIsModalOpen(false)}
          centered
          size={width <= 768 ? "95%" : "70%"}
        >
          <YoutubeVideo
            videoUrl={videoQuoteId}
            videoPoster="sales.png"
            autoplay
            // customOptions={width <= 500 ? mobileOps : opts}
            customOptions={{
              // eslint-disable-next-line no-nested-ternary
              height: width <= 500 ? "200px" : width < 1500 ? "400px" : "600px",
              width: "100%",
              playerVars: {
                autoplay: 1,
              },
            }}
          />
        </Modal>
      </Section>

      <Section className="mt-16 md:mt-32">
        <LargeFeatures sectionData={largeFeatureOne} titleSymbol=":" />
      </Section>

      <Section className="mt-16 md:mt-32">
        <Heading
          className="text-center"
          level={2}
          injectionType={2}
          withBlueSymbol
          symbol="?"
        >
          Are you an ideal fit
        </Heading>
        <p className="text-black-300 text-[20px] max-w-[915px] text-center mx-auto mb-10">
          Successful Account Executives are inspired by SpotOn’s heart and
          hustle culture and are inspired by their clients. Because, like the
          company, they believe that business is personal.
        </p>

        <Image
          imageName="sales-lp-diagram.png"
          className="max-w-[800px] mx-auto mt-10 md:mt-16"
        />

        <div className="text-black font-bold text-[18px] text-center">
          If you share our passion for helping businesses succeed and win,{" "}
          <a
            href="#openings"
            className="text-primary underline hover:underline"
          >
            Apply Now
          </a>
        </div>
      </Section>

      <Section className="mt-16 md:mt-32">
        <LargeFeatures sectionData={largeFeatureTwo} titleSymbol=":" />
      </Section>

      <Section className="mt-16">
        <CareersDisplayV2 careersDepartments={departments?.sales || []} />
      </Section>

      <Section className="mt-16">
        <Offices />
      </Section>

      <Section className="mt-10 md:mt-20">
        <Heading
          className="text-center"
          level={2}
          injectionType={2}
          withBlueSymbol
          symbol="!"
        >
          We’re a great place to work! Join us
        </Heading>

        <Image
          imageName="great-place-to-work-badges.png"
          className="max-w-[640px] mx-auto mt-10 mb-40"
        />
      </Section>

      <style jsx global>{`
        .careers-sales .slider-control-bottomcenter {
          bottom: -50px !important;
        }

        .careers-sales .slider-control-bottomcenter svg {
          fill: #1769ff;
        }
      `}</style>
    </Layout>
  );
};

export default SalesPage;
