export const videoQuotes = [
  //   {
  //     title: "Rick Curry",
  //     subTitle: "Regional Sales Director  ",
  //     description:
  //       "How long have you been with SpotOn and what had kept you here?",
  //     imageName: "sales-lp-rick-curry.png",
  //     videoId: "K3tXLnVQEO8",
  //   },
  {
    title: "Molly Heffler",
    subTitle: "Account Executive",
    description: "As a recent hire, what drew you to SpotOn?",
    imageName: "sales-lp-molly-heffler.png",
    videoId: "vsh2TnfXoS8",
  },
  //   {
  //     title: "Reed Corless",
  //     subTitle: "Team Lead",
  //     description:
  //       "What is the most challenging part of your job and what is the most rewarding? ",
  //     imageName: "sales-lp-reed-corless.png",
  //     videoId: "LOj_1gwvOWY",
  //   },
  {
    title: "Minako Garfinkel",
    subTitle: "Account Executive",
    description: "How has this job impacted your personal life?",
    imageName: "sales-lp-minako-harkinkel.png",
    videoId: "b6Z-Dxh9S2s",
  },
  {
    title: "Jake Olsen",
    subTitle: "Account Executive",
    description: "Explain your role in the simplest terms.",
    imageName: "sales-lp-jake-olson.png",
    videoId: "ld-dM6rr7os",
  },
  // {
  //   title: "Josh Carper",
  //   subTitle: "Regional Sales Manager",
  //   description: "What do you like most about your job and why?",
  //   imageName: "sales-lp-josh-carper.png",
  //   videoId: "",
  // },
  {
    title: "Guillermo Vera",
    subTitle: "Account Executive",
    description: "What makes the company’s culture unique?",
    imageName: "sales-lp-guillermo-vera.png",
    videoId: "7VlIcVEgCLA",
  },
  // {
  //   title: "Cameron Bonner",
  //   subTitle: "Area Sales Manager",
  //   description: "As a veteran, why did you decide to join SpotOn?",
  //   imageName: "sales-lp-cameron-bonner.png",
  //   videoId: "",
  // },
];

export const largeFeatureOne = {
  featureBlocks: [
    {
      blockTitle: "Our best in return for yours",
      blockImg: "sales-lf-1-1.png",
      blockList: [
        "Medical, Dental and Vision Insurance",
        "401k with company match",
        "Stock Options grant",
        "Flexible time off options",
        "Employee Resource Groups for Diversity and Inclusion, Women, LGBTQIA+, and other communities",
      ],
      forceReverse: false,
      // ctaPrime: {
      //   ctaTitle: "See our full benefits package",
      //   ctaTarget: "/Sales-Benefits-Overview.pdf",
      //   openInNewTab: true,
      // },
    },
  ],
};

export const largeFeatureTwo = {
  featureBlocks: [
    {
      blockTitle: "What makes us different",
      blockImg: "sales-lf-2-1.png",
      blockList: [
        "At SpotOn, creating meaningful and positive social impact while helping to build successful and long-lasting businesses is something we emphasize.",
        "The end result is a work environment where employees are proud of what they do, working as part of an extended, collaborative team to empower small businesses to thrive in a competitive marketplace.",
        "Our winning culture drives what we do – we impact what matters the most to business owners.",
      ],
      //       blockCustomSub: `At SpotOn, creating meaningful and positive social impact while helping to build successful and long-lasting businesses is something we emphasize.
      // <br/><br/>The end result is a work environment where employees are proud of what they do, working as part of an extended, collaborative team to empower small businesses to thrive in a competitive marketplace.
      // <br/><br/>Our winning culture drives what we do – we impact what matters the most to business owners.`,
    },
    {
      blockTitle: "What we believe in",
      blockImg: "sales-lf-2-2.png",
      blockSubTitle: `SpotOn is committed to supporting businesses at the heart of our communities with the solutions they need and designed to help them grow and win. `,
      blockList: [
        "We believe that business is personal and our clients—big or small, national or local—should never be on their own. We are right beside them, delivering the future technology they need, today and every day.",
        "We believe the best teams are diverse and inclusive.",
        "We believe a world of opportunity can be found within our growing company and that people develop through experiences.",
        "We believe delivering results and demonstrating teamwork drive advancement – both for Account Executives and for the company.",
      ],
      forceReverse: false,
    },
  ],
};
