import React from "react";
import { string, func } from "prop-types";
import Image from "../../components/ImageQuerys/RandomImages";
import play from "../../images/svg/play-blue.svg";

const VideoCard = ({
  className,
  title,
  subTitle,
  description,
  imageName,
  onClick,
}) => {
  return (
    <div
      type="button"
      className={`max-w-[250px] ${className} cursor-pointer group video-card`}
      onClick={onClick}
    >
      <div className="w-full relative">
        <Image imageName={imageName} className="w-[250px]" />

        <div
          className={`
          play-icon
          absolute w-[56px] h-[56px] bg-primary-30 rounded-full
          flex items-center justify-center
          left-0 right-0 top-0 bottom-0 m-auto
          group-hover:bg-primary-50
          transition transition-all
          z-[5]
        `}
        >
          <img className="w-[21px] relative z-[5]" src={play} alt="play" />
        </div>
      </div>
      <div className="text-black text-[20px] font-bold mt-4">
        {title}
        <span className="text-primary">.</span>
      </div>
      <div className="text-black text-p">{subTitle}</div>
      <p className="text-black-300 text-p mt-4">{description}</p>

      <style jsx>{`
        div.video-card {
          -webkit-appearance: none;
        }

        *:not(html) {
          -webkit-transform: translate3d(0, 0, 0);
        }
      `}</style>
    </div>
  );
};

VideoCard.propTypes = {
  className: string,
  title: string,
  subTitle: string,
  description: string,
  imageName: string.isRequired,
  onClick: func,
};

VideoCard.defaultProps = {
  className: "",
  title: "",
  subTitle: "",
  description: "",
  onClick: () => true,
};

export default VideoCard;
